import BaseDownload from '../../Components/BaseDownload';
import Manager from './Manager';

/* eslint-disable @typescript-eslint/no-unused-expressions */
export default class Download extends BaseDownload {
    constructor(options: any) {
        super(options);
        this.search = Manager.search.bind(this);
    }

    getData() {
        const t = this.t;
        const caches: any = this.getCaches().getState();
        const countriesMap = caches.countries.map[this.language()];
        const session = this.getSession().getState() as any;
        const header = [t('email'), t('employee'), t('approver'), t('position'), t('team'), t('office')];
        if (session.company.subsidiariesEnabled) {
            header.push(t('subsidiary'))
        }
        header.push(t('country'))
        const arr = [header].concat(this.state.employees.arr.map((employee: any) => {
            const ret: any = [employee.email, employee.fullName];
            const approver = (employee.approverCompanyUser && employee.approverCompanyUser.id > 0) ?
                (this.getCaches().getState() as any).users.map[employee.approverCompanyUser.id] : undefined;
            ret.push(approver && approver.id > 0 ? approver.fullName : '');
            ret.push(employee.position && employee.position.length > 0 ? employee.position : '');
            const companyUnit = (employee.companyUnit && employee.companyUnit.id) ?
                (this.getCaches().getState() as any).units.map[employee.companyUnit.id] : undefined;
            ret.push(companyUnit && companyUnit.name && companyUnit.name.length > 0 ?
                companyUnit.name : '');
            const office = (employee.office && employee.office.id) ?
                (this.getCaches().getState() as any).offices.map[employee.office.id] : undefined;
            ret.push(office?.name?.length > 0 ? office.name : '');
            if (session.company.subsidiariesEnabled) {
                const subsidiary = (employee.subsidiary && employee.subsidiary.id) ?
                    (this.getCaches().getState() as any).subsidiaries.map[employee.subsidiary.id] : undefined;
                ret.push(subsidiary?.name?.length > 0 ? subsidiary.name : '');
            }
            const country = (employee.country && employee.country.length > 0) ?
                countriesMap[employee.country].text : undefined;
            ret.push(country && country.length > 0 ? country : '');
            return ret;
        }));
        return arr;
    }

    public isReady() {
        return this.state.employees && this.state.employees.status === 'READY';
    }

    getFileName(this: any): any {
        const t = this.t;
        return this.ansi(t('employees')) + this.getSufixFileName();
    }
}