import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import InputField from '../../Components/InputField';
import SelectField from '../../Components/SelectField';
import Submit from '../../Components/Submit';
import TextareaField from '../../Components/TextareaField';
import {
    countriesOptionsFactory, currenciesOptionsFactory, currenciesOptionsFactoryRonOnly, subscriptionsIntervalOptionsFactory
} from '../../SelectOptionsFactories';
import { getURLParameter } from '../../Utils';
import Table from './Table';

export default class CreateOrder extends Component {
    static readonly UECountryCodes = new Set(['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LV', 'LU', 'MT', 'NL', 'PL', 'PT', 'SE', 'SI', 'SK', 'XI']);
    constructor(props: any) {
        super(props);
        let currentUsers = this.props.subscriptionOrder.users;
        let users = [this.props.subscriptionOrder.users];
        if ((this.getSession().getState() as any).company.id === 7033) {
            currentUsers = 75;
            users = [currentUsers];
        }
        if ((this.getSession().getState() as any).company.id === 6349) {
            currentUsers = 15;
            users = [currentUsers];
        }
        let currency = this.props.subscriptionOrder.billingInformation.country === 'RO' ?
            ['RON'] :
            ([getURLParameter('currency') ?
                getURLParameter('currency') : this.props.subscriptionOrder.currency]);
        let interval = [getURLParameter('interval') ?
            +getURLParameter('interval') : this.props.subscriptionOrder.interval];
        this.state = {
            companyName: {
                value: this.props.subscriptionOrder.billingInformation.companyName,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            email: {
                value: this.props.subscriptionOrder.billingInformation.email,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            phone: {
                value: this.props.subscriptionOrder.billingInformation.phone,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            firstName: {
                value: this.props.subscriptionOrder.billingInformation.firstName,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            lastName: {
                value: this.props.subscriptionOrder.billingInformation.lastName,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            country: {
                value: [this.props.subscriptionOrder.billingInformation.country],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            address: {
                value: this.props.subscriptionOrder.billingInformation.address,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            postalCode: {
                value: this.props.subscriptionOrder.billingInformation.postalCode,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            city: {
                value: this.props.subscriptionOrder.billingInformation.city,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            vatEnabled: {
                value: this.props.subscriptionOrder.vatEnabled,
            },
            vatNumber: {
                value: this.props.subscriptionOrder.billingInformation.vatNumber,
                touched: false,
                rules: this.Rules().BasicNumberRules()
            },
            vatNumberViesValidated: {
                value: this.props.subscriptionOrder.vatNumberViesValidated
            },
            commerceRegistry: {
                value: this.props.subscriptionOrder.billingInformation.commerceRegistryNumber,
                touched: false,
                rules: this.Rules().BasicNumberRules()
            },
            currentUsers: {
                value: currentUsers,
            },
            currency: {
                value: currency,
                touched: false,
                rules: this.Rules().BasicSelectRules(),
                render: true
            },
            interval: {
                value: interval,
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            users: {
                value: users,
                touched: false,
                rules: this.Rules().BasicNumberRules()
            },
            vatRate: this.props.subscriptionOrder.vatRate,
            validateVatNumber: {
                pleaseWait: false
            },
            reviewAndOrder: {
                pleaseWait: false
            }
        };
        this.createSubscriptionOrder();
    }

    componentDidMount() {
        this.propagateOnChange();
    }

    render() {
        const t = this.translate();
        const caches: any = this.getCaches().getState();
        const countriesOptions = countriesOptionsFactory(caches.countries.arr[this.language()]);
        const currenciesOptions = this.state.country.value[0] === 'RO' ? currenciesOptionsFactoryRonOnly() : currenciesOptionsFactory();
        const subscriptionsIntervalOptions = ((this.getSession().getState() as any).company.id === 7033) ||
            (this.getSession().getState() as any).company.id === 7884 || (this.getSession().getState() as any).company.id === 6349
            || (this.state?.country?.value?.length && this.state.country.value[0] === 'RO' && (this.getSession().getState() as any).company.id > 22800) ?
            [subscriptionsIntervalOptionsFactory(t)[1]] :
            subscriptionsIntervalOptionsFactory(t);
        const maxUsers = 1000;
        const usersOptions = Array.from(Array(maxUsers).keys()).map((i: any) => {
            const value = i + this.state.currentUsers.value;
            return { value: value, text: '' + value };
        });
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <form onSubmit={(event: any) => { this.submit(event); }}>
                    <fieldset>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <h5 className="m-0">{t('billing.information')}</h5>
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.companyName.value}
                                    touched={this.state.companyName.touched}
                                    rules={this.state.companyName.rules}
                                    type="text"
                                    placeholder={t('company')}
                                    focus={this.isScreenSizeSmall() ? false : true}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { companyName: Object.assign({}, this.state.companyName, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <InputField
                                    defaultValue={this.state.firstName.value}
                                    touched={this.state.firstName.touched}
                                    rules={this.state.firstName.rules}
                                    type="text"
                                    placeholder={t('first.name')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { firstName: Object.assign({}, this.state.firstName, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <InputField
                                    defaultValue={this.state.lastName.value}
                                    touched={this.state.lastName.touched}
                                    rules={this.state.lastName.rules}
                                    type="text"
                                    placeholder={t('last.name')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { lastName: Object.assign({}, this.state.lastName, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <InputField
                                    defaultValue={this.state.email.value}
                                    touched={this.state.email.touched}
                                    rules={this.state.email.rules}
                                    type="text"
                                    placeholder={t('email')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { email: Object.assign({}, this.state.email, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <InputField
                                    defaultValue={this.state.phone.value}
                                    touched={this.state.phone.touched}
                                    rules={this.state.phone.rules}
                                    type="text"
                                    placeholder={t('phone')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { phone: Object.assign({}, this.state.phone, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.country.value}
                                    touched={this.state.country.touched}
                                    options={countriesOptions}
                                    multiple={false}
                                    rules={this.state.country.rules}
                                    placeholder={t('country')}
                                    onChange={(value: any) => {
                                        let vatRate = 0;
                                        let vatEnabled = false;
                                        let vatNumberViesValidated = false;
                                        let currency = this.state.currency.value;
                                        if (value[0] === 'RO') {
                                            currency = ['RON'];
                                            vatEnabled = true;
                                            vatRate = 19;
                                        }
                                        if (CreateOrder.UECountryCodes.has(value[0])) {
                                            vatEnabled = true;
                                            vatRate = 19;
                                        }
                                        this.setState({
                                            country: Object.assign({}, this.state.country, { value: value }),
                                            currency: Object.assign({}, this.state.currency, { value: currency, render: false }),
                                            vatEnabled: { value: vatEnabled }, vatRate: { value: vatRate },
                                            vatNumberViesValidated: { value: vatNumberViesValidated },
                                        }, () => { this.setState({ currency: Object.assign({}, this.state.currency, { render: true }) }, () => { this.propagateOnChange(); }); });
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <InputField
                                    defaultValue={this.state.postalCode.value}
                                    touched={this.state.postalCode.touched}
                                    rules={this.state.postalCode.rules}
                                    type="text"
                                    placeholder={t('postal.code')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { postalCode: Object.assign({}, this.state.postalCode, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <InputField
                                    defaultValue={this.state.city.value}
                                    touched={this.state.city.touched}
                                    rules={this.state.city.rules}
                                    type="text"
                                    placeholder={t('city')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { city: Object.assign({}, this.state.city, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <TextareaField
                                    defaultValue={this.state.address.value}
                                    touched={this.state.address.touched}
                                    rules={this.state.address.rules}
                                    placeholder={t('address')}
                                    rows={2}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { address: Object.assign({}, this.state.address, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div
                                className={'col-12 mb-3' +
                                    ((this.state.country.value && (this.state.country.value.length > 0) &&
                                        (this.state.country.value[0] === 'RO')) ? '' : ' d-none')}
                            >
                                <InputField
                                    defaultValue={this.state.commerceRegistry.value}
                                    touched={this.state.commerceRegistry.touched}
                                    rules={this.state.commerceRegistry.rules}
                                    type="text"
                                    placeholder={t('commerce.registry')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { commerceRegistry: Object.assign({}, this.state.commerceRegistry, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            {((this.state?.country?.value?.length &&
                                ((this.state.country.value[0] === 'RO') || CreateOrder.UECountryCodes.has(this.state.country.value[0])))) ?
                                <div className="col-12 mb-3">
                                    <InputField
                                        defaultValue={this.state.vatNumber.value}
                                        touched={this.state.vatNumber.touched}
                                        rules={this.state.vatNumber.rules}
                                        type="text"
                                        placeholder={t('vat.number')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                {
                                                    vatNumber: Object.assign({}, this.state.vatNumber, { value: value }),
                                                    vatNumberViesValidated: Object.assign({}, this.state.vatNumberViesValidated, { value: value }),
                                                }, () => { this.propagateOnChange(); }
                                            );
                                        }}
                                    />
                                    {(this.state.country.value[0] !== 'RO' && this.state?.country?.value?.length && CreateOrder.UECountryCodes.has(this.state.country.value[0])) ?
                                        <>
                                            {this.state.vatNumberViesValidated.value ?
                                                <small className="text-success">{t('the.vat.number.was.validated.by.the.vies.service.')}</small> :
                                                <small className="text-info">{t('the.vat.number.is.not.validated.by.the.vies.service.')}</small>}
                                        </>
                                        : null}
                                </div> : null}
                            {(this.state?.country?.value?.length && CreateOrder.UECountryCodes.has(this.state.country.value[0])) ?
                                <div className="col-12 mb-3">
                                    <Submit type="button"
                                        faIcon="fas fa-map-marker"
                                        pleaseWait={this.state.validateVatNumber.pleaseWait}
                                        onClick={(event: any) => { this.validateVatNumber(event); }}
                                    >
                                        {t('validate.vat.number')}
                                    </Submit>
                                </div>
                                : null}
                            <div className="col-12 mb-3">
                                <h5 className="m-0">{t('subscription')}</h5>
                            </div>
                            {this.state.currency.render ?
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.currency.value}
                                        touched={this.state.currency.touched}
                                        options={currenciesOptions}
                                        multiple={false}
                                        rules={this.state.currency.rules}
                                        placeholder={t('currency')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { currency: Object.assign({}, this.state.currency, { value: value }) }, () => { this.propagateOnChange(); }
                                            );
                                        }}
                                    />
                                </div>
                                : null}
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.interval.value}
                                    touched={this.state.interval.touched}
                                    options={subscriptionsIntervalOptions}
                                    multiple={false}
                                    rules={this.state.interval.rules}
                                    placeholder={t('interval')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { interval: Object.assign({}, this.state.interval, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.users.value}
                                    touched={this.state.users.touched}
                                    options={usersOptions}
                                    multiple={false}
                                    rules={this.state.users.rules}
                                    placeholder={t('employees')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { users: Object.assign({}, this.state.users, { value: value }) }, () => { this.propagateOnChange(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <Table subscriptionOrder={this.props.subscriptionOrder} />
                            </div>
                            <div className="col-12 mb-3">
                                <Submit
                                    faIcon="fa fa-shopping-cart"
                                    pleaseWait={this.state.reviewAndOrder.pleaseWait}
                                >
                                    {t('review.and.order')}
                                </Submit>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div >
    }

    propagateOnChange() {
        if (this.props.onChange) { this.props.onChange(this.createSubscriptionOrder()); }
    }

    isFormValid() {
        if (this.state.country.value[0] === 'RO') {
            if ((this.Validator().validate(this.state.vatNumber) &&
                this.Validator().validate(this.state.commerceRegistry)) === false) {
                return false;
            }
            //            return false;
        }
        return this.Validator().validate(this.state.companyName) &&
            this.Validator().validate(this.state.email) &&
            this.Validator().validate(this.state.phone) &&
            this.Validator().validate(this.state.firstName) &&
            this.Validator().validate(this.state.lastName) &&
            this.Validator().validate(this.state.country) &&
            this.Validator().validate(this.state.address) &&
            this.Validator().validate(this.state.postalCode) &&
            this.Validator().validate(this.state.city) &&
            this.Validator().validate(this.state.currency) &&
            this.Validator().validate(this.state.interval) &&
            this.Validator().validate(this.state.users);
    }

    validateVatNumber(event: any) {
        event.preventDefault();
        const t = this.translate();
        $.ajax({
            type: 'GET',
            url: this.Endpoints().viesValidate(this.state.country.value, this.state.vatNumber.value),
            cache: false,
            beforeSend: () => {
                this.setState({
                    validateVatNumber: { pleaseWait: true },
                    reviewAndOrder: { pleaseWait: true }
                });
            },
            success: (data, textStatus, jqXHR) => {
                this.setState({
                    vatEnabled: { value: true },
                    vatRate: { value: 0 },
                    vatNumberViesValidated: { value: data.valid },
                }, () => { this.propagateOnChange(); });
                this.setState({
                    validateVatNumber: { pleaseWait: false },
                    reviewAndOrder: { pleaseWait: false }
                });
                if (!data.valid)
                    this.errorToastr(t('invalid.VAT.number.for.cross.border.transactions.within.the.EU'))
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({ validateVatNumber: { pleaseWait: false } });
            },
        });
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                companyName: Object.assign({}, this.state.companyName, { touched: true }),
                email: Object.assign({}, this.state.email, { touched: true }),
                phone: Object.assign({}, this.state.phone, { touched: true }),
                firstName: Object.assign({}, this.state.firstName, { touched: true }),
                lastName: Object.assign({}, this.state.lastName, { touched: true }),
                country: Object.assign({}, this.state.country, { touched: true }),
                address: Object.assign({}, this.state.address, { touched: true }),
                postalCode: Object.assign({}, this.state.postalCode, { touched: true }),
                city: Object.assign({}, this.state.city, { touched: true }),
                vatNumber: Object.assign({}, this.state.vatNumber, { touched: true }),
                commerceRegistry: Object.assign({}, this.state.commerceRegistry, { touched: true }),
                currency: Object.assign({}, this.state.currency, { touched: true }),
                interval: Object.assign({}, this.state.interval, { touched: true }),
                users: Object.assign({}, this.state.users, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    $.ajax({
                        type: 'POST',
                        contentType: 'application/json',
                        url: this.Endpoints().getCompanyInformation(),
                        dataType: 'json',
                        cache: false,
                        data: JSON.stringify({
                            billingInformation: {
                                companyName: this.state.companyName.value,
                                email: this.state.email.value,
                                phone: this.state.phone.value,
                                firstName: this.state.firstName.value,
                                lastName: this.state.lastName.value,
                                country: this.state.country.value[0],
                                address: this.state.address.value,
                                postalCode: this.state.postalCode.value,
                                city: this.state.city.value,
                                vatNumber: this.state.vatNumber.value,
                                commerceRegistryNumber: this.state.commerceRegistry.value,
                            }
                        }),
                        beforeSend: () => {
                            this.setState({ reviewAndOrder: { pleaseWait: true } });
                        },
                        success: (data, textStatus, jqXHR) => {
                            if (this.props.onOrder) { this.props.onOrder(this.createSubscriptionOrder()); }
                        },
                        error: (jqXHR, textStatus, errorThrown) => {
                            this.ajaxError(jqXHR, textStatus, errorThrown);
                            this.setState({ reviewAndOrder: { pleaseWait: false } });
                        },
                    });
                }
            }
        );
    }

    getVatRate() {
        if (CreateOrder.UECountryCodes.has(this.state.country.value[0]) === false && this.state.country.value[0] !== 'RO') {
            return 0;
        }
        if (CreateOrder.UECountryCodes.has(this.state.country.value[0]) && this.state.vatNumberViesValidated.value) {
            return 0;
        }
        return 19;
    }

    createSubscriptionOrder() {
        let vat = this.getVatRate();
        return Object.assign({}, this.props.subscriptionOrder, {
            billingInformation: {
                companyName: this.state.companyName.value,
                email: this.state.email.value,
                phone: this.state.phone.value,
                firstName: this.state.firstName.value,
                lastName: this.state.lastName.value,
                country: this.state.country.value[0],
                address: this.state.address.value,
                postalCode: this.state.postalCode.value,
                city: this.state.city.value,
                vatNumber: this.state.vatNumber.value,
                commerceRegistryNumber: this.state.commerceRegistry.value,
            }
        }, {
            country: this.state.country.value[0],
            vatEnabled: this.state.vatEnabled.value,
            vatRate: vat,
            vatNumberViesValidated: this.state.vatNumberViesValidated.value,
            currency: this.state.currency.value[0],
            interval: this.state.interval.value[0],
            users: this.state.users.value[0],
            discountInterval: this.state.interval.value[0] === 12 ? 2 : 0,
            pricePerUser: this.props.orderSettings.prices[this.state.currency.value[0]],
        });
    }
}